import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Image, Form, Card, } from 'react-bootstrap'
import Message from '../components/message'
import { addToCart, removeFromCart } from '../actions/cartActions'
import {
  Button,
  ButtonGroup,

} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function CartScreen({ match, location, history }) {
  const productId = match.params.id
  const qty = location.search ? Number(location.search.split('=')[1]) : 1
  const dispatch = useDispatch()


  const cart = useSelector(state => state.cart)
  const { cartItems } = cart
  
  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty))

    }
  }, [dispatch, productId, qty])


  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
  }


  const checkoutHandler = () => {
    history.push('/login?redirect=shipping')
  }

  const plusMinusCart = (cartItem, cartQty) => {
    dispatch(addToCart(cartItem, cartQty))
  }


  return (
    <Row>
      <Col md={8}>
        <h1>Shopping Cart</h1>

        {cartItems.length === 0 ? (
          <Message variant='info'>
            Your cart is empty <Link to='/'>Go Back</Link>
          </Message>
        ) : (
          <ListGroup variant='flush'>
            {cartItems.map(item => (
              <ListGroup.Item key={item.product}>
                <Row>
                  <Col md={2}>
                    <Image src={item.image} alt={item.name} fluid rounded />
                  </Col>
                  <Col md={3}>
                    <Link to={`/product/${item.product}`}>{item.name}</Link>
                  </Col>

                  <Col md={2}>
                    ${item.price}
                  </Col>
                  <Col md={3}>




                 
                    <ButtonGroup size="large" variant="text" color="inherit" sx={{ border: '1px solid', borderColor: 'grey.400' }}>
                      <Button key="three" disabled={item.qty <= 1} onClick={(e) => plusMinusCart(item.product, (Number(item.qty) - 1))} sx={{ pr: 0.75, pl: 0.75, minWidth: '0px !important' }}>
                        <RemoveIcon fontSize="inherit" />
                      </Button>
                          <Button key="two" sx={{ pl: 0.5, pr: 0.5 }}>
                            {item.qty}
                          </Button>

                          <Button key="one" onClick={(e) => plusMinusCart(item.product, (Number(item.qty) + 1))} sx={{ pl: 0.75, pr: 0.75, minWidth: '0px !important' }}>
                            <AddIcon fontSize="inherit" />
                          </Button>
                        
                     
                    </ButtonGroup>
                    

                    

                  </Col>

                  <Col md={1}>

                    <Button
                      type='button'
                      variant='light'
                      onClick={() => removeFromCartHandler(item.product)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </Col>
                </Row>

              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Col>
      <Col md={4}>
        <Card>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>
                Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)})
              </h2>
              ${cartItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2)}
            </ListGroup.Item>
          </ListGroup>

          <ListGroup.Item>
            <Button
              type="button"
              className="btn-block"
              disabled={cartItems.length === 0}
              onClick={checkoutHandler}
            >
              Proceed to checkout
            </Button>
          </ListGroup.Item>
        </Card>
      </Col>
    </Row>
  )
}

export default CartScreen
