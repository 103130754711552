import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

function Footer() {
  const currentYear = new Date().getFullYear();

  return (

    <footer>
      <Container>
        <Row>
          <Col className="text-center py-3">
            
            Copyright &copy; Soko Mjini  
            <a href='https://kagus-code.web.app/' target='_blank'>          
              <p>Developed by Eston &copy; {currentYear}</p>
            </a>
          
           </Col>
        </Row>

        
      </Container>
    </footer>

  )
}

export default Footer
