
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Product from '../components/Product'
import Loader from '../components/loader'
import Message from '../components/message'
import Paginate from '../components/Paginate'
import { getUserDetails, updateUserProfile,logout } from '../actions/userActions'
import ProductCarousel from '../components/ProductCarousel'
import { listProducts } from '../actions/productActions'
import FloatingCart from '../ui-component/cards/FloatingCart';



function HomeScreen({ history }) {

  const dispatch = useDispatch()

  const userDetails = useSelector(state => state.userDetails)
  const { error:userError } = userDetails

  const productList = useSelector(state => state.productList)
  const { error, loading, products, page, pages } = productList

  let keyword = history.location.search
  useEffect(() => {
    if (userError){
      dispatch(logout())
    }
    dispatch(listProducts(keyword))





  }, [dispatch, keyword])


  return (
    <div>
      {!keyword && <ProductCarousel />}

      <h1> LATEST PRODUCTS</h1>
      {loading ? <Loader />

        : error ? <Message variant='danger'>{error}</Message>
          :
          <div>
            <Row>
              {products.map(product => (
                <Col key={product._id} xs={6} sm={4} md={3} lg={3}>
                  <Product product={product} />
                </Col>
              ))}
            </Row>
            <Paginate page={page} pages={pages} keyword={keyword} />
            <FloatingCart />

          </div>

      }



    </div>
  )
}

export default HomeScreen
