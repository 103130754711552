import React, { useState } from 'react'
import { addToCart, removeFromCart } from '../actions/cartActions'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Link, } from 'react-router-dom'
import { Button, CardContent, CardMedia, Grid, Rating, Stack, Typography } from '@mui/material';
import MainCard from '../ui-component/cards/MainCard';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';

function Product({ product}) {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
    const addToCartHandler = (product) => {
        dispatch(addToCart(product._id, 1))
        setOpen(true);
      }
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };


      const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );


      const [showMore, setShowMore] = useState(false);
const maxCharacters = 10; // Change this to the desired maximum number of characters

const handleClickMore = () => {
  setShowMore(true);
};

const truncatedProductName = product.name.substring(0, maxCharacters);
const truncatedProductDescription = product.name.substring(0, 20);

  return (







<MainCard
                    content={false}
                    boxShadow
                    sx={{
                        '&:hover': {
                            transform: 'scale3d(1.02, 1.02, 1)',
                            transition: 'all .4s ease-in-out'
                        }
                    }}
                >
                    <CardMedia
                        sx={{ height: 220 }}
                        image={product.image}
                        title="Contemplative Reptile"
                        component={Link}
                        to={`/product/${product._id}`}
                    />
                    <CardContent sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            <Typography
          component={Link}
          to={`/product/${product._id}`}
          variant="subtitle1"
          sx={{ textDecoration: 'none' }}
        >
          {showMore ? product.name : truncatedProductName}
          {!showMore && product.name.length > maxCharacters && (
            <Button onClick={handleClickMore}>more</Button>
          )}
        </Typography>
                            </Grid>
                            {product.description && (
                                <Grid item xs={12}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            overflow: 'hidden',
                                            height: 45
                                        }}
                                    >
                                        {truncatedProductDescription}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={12} sx={{ pt: '8px !important' }}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Rating precision={0.5} name="size-small" value={product.rating} size="small" readOnly />
                                    <Typography variant="caption">({product.price}+)</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Typography variant="h4" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>${product.price}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6" sx={{
            fontSize: { xs: '1rem', sm: '1.5rem' },
            color: 'grey.500',
            textDecoration: 'line-through'
          }}>
                                                ${product.price}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Button  variant="contained" sx={{ minWidth: 0 }} 
                                     onClick={()=>{addToCartHandler(product)}}
                                    >
                                        <ShoppingCartTwoToneIcon fontSize="small" />

<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
  <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
    item added to cart
  </Alert>
</Snackbar>
                                    
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                </MainCard>






  )
}

export default Product
